<template>
  <LiefengContent>
    <template v-slot:title>计划管理</template>
    <template v-slot:toolsbarRight>
      <Button type="primary" icon="md-add" @click="addPlan">新建计划</Button>
      <Button type="warning" icon="ios-settings-outline" @click="settingService"
        >服务项设置</Button
      >
      <Button
        type="error"
        @click="$router.push('/archivesindex')"
        icon="ios-arrow-back"
        >返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 新增 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        @input="addChangeStatusFn"
        :fullscreen="true"
        class="add-change"
      >
        <template v-slot:contentarea>
          <AddChangePlan
            v-if="addChangeStatus"
            :addChangeIden="addChangeIden"
            @successSave="successSave"
            :planTableList="planTableList"
            :optionsList="optionsList"
            ref="addChangePlan"
          ></AddChangePlan>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModalTip = true;
              addChangeStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >关闭</Button
          >
          <Button @click="saveAddChange" type="primary">保存</Button>
        </template>
      </LiefengModal>
      <!-- 查看 -->
      <LiefengModal
        title="查看计划"
        :value="seeStatus"
        @input="seeStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <SeePlan
            v-if="seeStatus"
            :planTableList="planTableList"
            :optionsList="optionsList"
          ></SeePlan>
        </template>
        <template v-slot:toolsbar>
          <Button @click="seeStatusFn(false)" type="primary">关闭</Button>
        </template>
      </LiefengModal>
      <!-- 服务进程 -->
      <LiefengModal
        title="服务进程"
        :value="serviceProcessStatus"
        @input="serviceProcessStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <ServiceProcess
            :serviceYearofmonth="serviceYearofmonth"
            v-if="serviceProcessStatus"
          ></ServiceProcess>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/archivesplan')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import AddChangePlan from "@/views/archives/childrens/addChangePlan";
import ServiceProcess from "@/views/archives/childrens/serviceProcess";
import SeePlan from "@/views/archives/childrens/seePlan";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "服务月份",
          key: "yearofmonth",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            let result = params.row.yearofmonth
              ? params.row.yearofmonth.substring(
                  0,
                  params.row.yearofmonth.length - 2
                ) +
                "-" +
                params.row.yearofmonth.substring(
                  params.row.yearofmonth.length - 2
                )
              : "";
            return h("span", result);
          },
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          align: "center",
          minWidth: 200,
        },
        {
          title: "状态",
          key: "status",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  color: params.row.status == "偏离计划" ? "red" : "",
                },
              },
              params.row.status
            );
          },
        },
        {
          title: "创建人",
          key: "staffName",
          align: "center",
          width: 150,
        },
        {
          title: "操作",
          align: "center",
          width: 360,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "info",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.$Message.loading({
                        content: "正在加载数据，请稍等...",
                        duration: 0,
                      });
                      this.$get(
                        "/archives/api/pc/servicePlan/queryServicePlanDetail",
                        {
                          orgCode: parent.vue.loginInfo.userinfo.orgCode,
                          yearofmonth: params.row.yearofmonth,
                        }
                      ).then((res) => {
                        if (res.code == 200 && res.dataList) {
                          this.planTableList = res.dataList;
                          // 获取服务内容项
                          this.$get(
                            "/archives/api/pc/serviceProject/queryList",
                            {
                              oemCode: parent.vue.oemInfo.oemCode,
                              orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            }
                          )
                            .then((res) => {
                              this.$Message.destroy();
                              if (res.code == 200 && res.dataList) {
                                this.planTableList.map((item) => {
                                  res.dataList.map((subItem) => {
                                    if (item.servicePlanProjectVos) {
                                      item.servicePlanProjectVos.map((k) => {
                                        if (subItem.projectId == k.projectId) {
                                          subItem.frequency = k.frequency;
                                        }
                                      });
                                    }
                                  });
                                  this.$Message.destroy();
                                  this.optionsList = res.dataList;
                                  this.seeStatus = true;
                                });
                              } else {
                                this.$Message.error({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            })
                            .catch((err) => {
                              this.$Message.destroy();
                              console.log(err);
                              this.$Message.error({
                                background: true,
                                content: "获取服务内容失败，请联系管理员处理",
                              });
                            });
                        }
                      });
                    },
                  },
                },
                "查看"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "primary",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.addChangeTitle = "修改计划";
                      this.addChangeIden = false;
                      this.$Message.loading({
                        content: "正在加载数据，请稍等...",
                        duration: 0,
                      });
                      this.$get(
                        "/archives/api/pc/servicePlan/queryServicePlanDetail",
                        {
                          orgCode: parent.vue.loginInfo.userinfo.orgCode,
                          yearofmonth: params.row.yearofmonth,
                        }
                      ).then((res) => {
                        if (res.code == 200 && res.dataList) {
                          this.planTableList = res.dataList;
                          // 获取服务内容项
                          this.$get(
                            "/archives/api/pc/serviceProject/queryList",
                            {
                              oemCode: parent.vue.oemInfo.oemCode,
                              orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            }
                          )
                            .then((res) => {
                              this.$Message.destroy();
                              if (res.code == 200 && res.dataList) {
                                this.planTableList.map((item) => {
                                  res.dataList.map((subItem) => {
                                    if (item.servicePlanProjectVos) {
                                      item.servicePlanProjectVos.map((k) => {
                                        if (subItem.projectId == k.projectId) {
                                          subItem.frequency = k.frequency;
                                        }
                                      });
                                    }
                                  });
                                  this.$Message.destroy();
                                  this.optionsList = res.dataList;
                                  this.addChangeStatus = true;
                                });
                              } else {
                                this.$Message.error({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            })
                            .catch((err) => {
                              this.$Message.destroy();
                              console.log(err);
                              this.$Message.error({
                                background: true,
                                content: "获取服务内容失败，请联系管理员处理",
                              });
                            });
                        }
                      });
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "error",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content:
                          "<p>您正在删除服务计划，此操作不可逆，是否继续</p>",
                        onOk: () => {
                          this.$post("/archives/api/pc/servicePlan/delete", {
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            yearofmonth: params.row.yearofmonth,
                          }).then((res) => {
                            if (res.code == 200) {
                              this.$Message.success({
                                background: true,
                                content: "删除成功",
                              });
                              this.hadlePageSize({
                                page: this.page,
                                pageSize: this.pageSize,
                              });
                            }
                          });
                        },
                      });
                    },
                  },
                },
                "删除"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "warning",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.serviceYearofmonth = params.row.yearofmonth;
                      this.serviceProcessStatus = true;
                    },
                  },
                },
                "服务进程"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "success",
                  },
                  on: {
                    click: () => {
                      this.addChangeTitle = "新增计划";
                      this.addChangeIden = true;
                      this.$Message.loading({
                        content: "正在加载数据，请稍等...",
                        duration: 0,
                      });
                      this.$get(
                        "/archives/api/pc/servicePlan/queryServicePlanDetail",
                        {
                          orgCode: parent.vue.loginInfo.userinfo.orgCode,
                          yearofmonth: params.row.yearofmonth,
                        }
                      ).then((res) => {
                        if (res.code == 200 && res.dataList) {
                          this.planTableList = res.dataList;
                          // 获取服务内容项
                          this.$get(
                            "/archives/api/pc/serviceProject/queryList",
                            {
                              oemCode: parent.vue.oemInfo.oemCode,
                              orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            }
                          )
                            .then((res) => {
                              this.$Message.destroy();
                              if (res.code == 200 && res.dataList) {
                                this.planTableList.map((item) => {
                                  res.dataList.map((subItem) => {
                                    if (item.servicePlanProjectVos) {
                                      item.servicePlanProjectVos.map((k) => {
                                        if (subItem.projectId == k.projectId) {
                                          subItem.frequency = k.frequency;
                                        }
                                      });
                                    }
                                  });
                                  this.$Message.destroy();
                                  this.optionsList = res.dataList;
                                  this.addChangeStatus = true;
                                });
                              } else {
                                this.$Message.error({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            })
                            .catch((err) => {
                              this.$Message.destroy();
                              console.log(err);
                              this.$Message.error({
                                background: true,
                                content: "获取服务内容失败，请联系管理员处理",
                              });
                            });
                        }
                      });
                    },
                  },
                },
                "复制"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 20,
      loading: false,
      //新增/修改
      addChangeTitle: "",
      addChangeStatus: false,
      info: "",
      planTableList: [],
      cancelModalTip: false,
      addChangeIden: false,

      // 新增修改的服务内容
      optionsList: [],

      //查看
      seeStatus: false,

      // 服务进程
      serviceProcessStatus: false,
      serviceYearofmonth: "",
    };
  },
  methods: {
    // 新增计划
    addPlan() {
      // 获取服务项
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.addChangeIden = true;
      this.planTableList = [];
      this.$get("/archives/api/pc/serviceProject/queryList", {
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200 && res.dataList) {
            this.optionsList = res.dataList;
            this.addChangeStatusFn(true);
            this.addChangeTitle = "新增计划";
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取服务内容失败，请联系管理员处理",
          });
        });
    },
    // 查看计划
    seeStatusFn(status) {
      this.seeStatus = status;
    },
    //服务项设置
    settingService() {
      this.$router.push({
        path: "/settingservice",
      });
    },
    //服务进程
    serviceProcessStatusFn(status) {
      this.serviceProcessStatus = status;
    },
    //新增/修改
    addChangeStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    //保存
    saveAddChange() {
      this.$refs.addChangePlan.save();
    },
    // 新增修改保存成功
    successSave() {
      this.cancelModalTip = false;
      this.addChangeStatusFn(false);
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/archives/api/pc/servicePlan/queryPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              if (item.gmtCreate)
                item.gmtCreate = this.$core.formatDate(
                  new Date(item.gmtCreate),
                  "yyyy-MM-dd hh:mm:ss"
                );
              if (item.status) {
                switch (item.status) {
                  case "1":
                    item.status = "如期进行中";
                    break;
                  case "2":
                    item.status = "偏离计划";
                    break;
                  case "3":
                    item.status = "已完成";
                    break;
                }
              }
            });
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.page = res.currentPage;
            this.tableData = res.dataList;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddChangePlan,
    ServiceProcess,
    SeePlan,
  },
};
</script>
    
<style scoped lang='less'>
.add-change {
  /deep/.ivu-modal-close {
    display: none;
  }
  /deep/.ivu-table-wrapper {
    height: calc(100vh - 240px);
  }
}
</style>